import React from "react"

import CommandScanner from './command_scanner'
import ScanFeedback from './scan_feedback'
import Switcher from '../mode_switcher/switcher'

const Scanner = () => {
  

  return(
    <>
      <CommandScanner />
      <Switcher />
      <ScanFeedback />
      {/* <div className="border mt-3 p-3">
        <div className="d-flex">
          <img src="https://randomuser.me/api/portraits/men/40.jpg" className="img-thumbnail rounded mr-3" />
          <h4 className="ms-3">DELA CRUZ<br/>JUAN</h4>
        </div>
        <div className="alert my-3 text-center bg-info">
          <h5 className="m-0">ALLOWED ENTRY</h5>
        </div>
        <h5 className="text-center">
          10:30:01 AM - Jan 31
        </h5>
      </div> */}
    </>
  )
}

export default Scanner
