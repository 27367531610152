import React from "react"

const Invalid = ({scan}) => {
  const {created_at} = scan

  return(<>
    <div className="border mt-3 p-3">
      <div className="alert my-3 text-center text-white bg-danger">
        <h5 className="m-0">INVALID CODE</h5>
      </div>
      <h5 className="text-center">
        {created_at}
      </h5>
    </div>
  </>)
}

export default Invalid