import {
  LOAD,
  DETAIL,
} from '../const/waitings_const'

export const loadWaitings = () => dispatch => {
  dispatch({
    type: LOAD,
    payload: {}
  })
}

export const showDetail = (id) => dispatch => {
  dispatch({
    type: DETAIL,
    payload: id
  })
}