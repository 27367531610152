import React, {useEffect} from "react"
import { useSelector, useDispatch } from 'react-redux'
import {
  loadSummary,
  showInside,
  showOutside
} from '../../actions/attendance_action'
import {
  doCommand
} from '../../actions/general_action'
import List from "./list"
import Barcode from "react-barcode"

export default () => {
  const dispatch = useDispatch()
  const { display_mode, inside_list, outside_list } = useSelector((state) => state.attendance)

  useEffect(() => {
    dispatch(loadSummary())
  }, [])

  const onShowInside = () => {
    dispatch(doCommand('att-in'))
  }

  const onShowOutside = () => {
    dispatch(doCommand('att-out'))
  }

  const switcherDisplayMode = () => {
    if(display_mode === 'inside') {
      return <button className="btn btn-md btn-warning p-1" onClick={onShowOutside}>
        <Barcode value={`att-out`} width={1.5} height={30} displayValue={false} />
        {/* <h5 className="m-0">Show Outside</h5> */}
      </button>
    } else if (display_mode === 'outside') {
      return <button className="btn btn-md btn-success p-1" onClick={onShowInside}>
        <Barcode value={`att-in`} width={1.5} height={30} displayValue={false} />
        {/* <h5 className="m-0">Show Inside</h5> */}
      </button>
    }
  }

  const bgMode = () => {
    if (display_mode === 'inside') {
      return 'alert-success'
    } else if  (display_mode === 'outside') {
      return 'alert-warning'
    } else {
      return 'alert-secondary'
    }
  }

  const headerElem = () => {
    if (display_mode === 'inside') {
      return <div>
        <h3>Inside: {inside_list.length} &nbsp;&nbsp; <span className="text-secondary">Outside: {outside_list.length}</span></h3>
      </div>
    } else if  (display_mode === 'outside') {
      return <div>
        <h3>Outside: {outside_list.length} &nbsp;&nbsp; <span className="text-secondary">Inside: {inside_list.length}</span></h3>
      </div>
    }
  }

  return(
    <div className={`p-3 alert ${bgMode()}`}>
      <div className="d-flex justify-content-between">
        {headerElem()}
        <div>{switcherDisplayMode()}</div>
      </div>
      {display_mode === 'inside'  && <List list={inside_list} />}
      {display_mode === 'outside' && <List list={outside_list} />}
    </div>
  )
}
