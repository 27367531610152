import React from "react"
import { useDispatch } from 'react-redux'
import Barcode from "react-barcode"

import { doCommand } from '../../actions/general_action'

export default ({person}) => {
  const dispatch = useDispatch()

  const {id, last_name, first_name, bar_code} = person

  const viewDetail = () => {
    dispatch(doCommand(`opt-${id}`))
  }

  // const imgAvatar = <>
  //   <img
  //     src={`https://randomuser.me/api/portraits/men/${id}.jpg`}
  //     className="rounded mr-3"
  //     style={{width: '80px', height: '80px'}}
  //   />
  // </>

  const imgAvatar = <>
    <img
      src={`http://via.placeholder.com/300`}
      className="rounded mr-3"
      style={{width: '80px', height: '80px'}}
    />
  </>

  const fullName = <h5 className="ms-3">{last_name}<br/>{first_name}</h5>

  // const barCode = <Barcode value={`opt-${id}`} width={1.5} height={50} displayValue={true} />
  const barCode = <Barcode value={bar_code} width={1.5} height={50} displayValue={true} />

  return(
    <>
      <div
        className="gate-waiting border border-3 rounded p-3 m-3 bg-light"
        style={{width: '275px', cursor: 'pointer'}}
        onClick={viewDetail}
      >
        <div className="d-flex mb-3">
          {imgAvatar}
          {fullName}
        </div>
        <div className="text-center">{barCode}</div>
      </div>
    </>
  )
}
