import store from '../store'

import {
  LOAD,
} from '../const/waitings_const'

import {
  SET_LATEST,
  SUBMITTING_SCAN
} from '../const/scans_const'

import {
  showDetail
} from './waitings_action'

import {
  changeMode,
  showSwitcherMode
} from './mode_switcher_action'

import {
  changeAttendanceView
} from './attendance_action'

import {
  activityLog
} from './scan_action'

import {
  showClaimingMode,
  undoClaiming
} from './claims_action'

export const doCommand = (str_command) => dispatch => {
  document.getElementById("main-command-scanner").focus()
  const command = str_command.split('-')
  
  switch (command[0]) {
    case 'mode':
      dispatch(changeMode(command[1]))
      return
    case 'switcher':
      dispatch(showSwitcherMode())
      return
    case 'att':
      dispatch(changeAttendanceView(command[1]))
      return
    case 'opt':
      dispatch(showDetail(command[1]))
      return
    case 'claims':
      dispatch(showClaimingMode(command[1]))
      return
    case 'undo_cl':
      dispatch(undoClaiming(command[1]))
      return
    case 'allow':
      if(command[1] && command[1] === 'entry') {
        // alert('allow entry: ' + command[2])
        dispatch(checkCode('entered'))
      } else if(command[1] && command[1] === 'exit') {
        // alert('allow exit: ' + command[2])
        dispatch(checkCode('exited'))
      }
      dispatch(doCancel('cancel'))
      return
    case 'deny':
      if(command[1] && command[1] === 'entry') {
        // alert('deny entry: ' + command[2])
        dispatch(checkCode('entry-unallowed'))
      } else if(command[1] && command[1] === 'exit') {
        // alert('deny exit: ' + command[2])
        dispatch(checkCode('exit-unallowed'))
      }
      dispatch(doCancel('cancel'))
      return
    case 'noti':
      // alert('noti: ' + command[1])
      dispatch(checkCode('notified'))
      return
    case 'cancel':
      dispatch(doCancel(str_command))
      return
    default:
      dispatch(enteredMemberCode(str_command))
      return
  }
}

export const doCancel = (command) => dispatch => {
  dispatch({
    type: LOAD,
    payload: {}
  })
}

export const enteredMemberCode = (code) => dispatch => {
  const mode = store.getState().switcher.mode;
  const other_id = store.getState().claims.claimable_id;

  dispatch(
    activityLog(code, mode, other_id)
  )
}

export const fetchCode = (code) => {

  switch (code) {
    case 'entered':
      return {
        status: 'entered',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        id: 3
      }
    case 'allowed-entry':
      return {
        status: 'allowed-entry',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        instruction: 'Please check bag and pockets',
        id: 3
      }
    case 'entry-unallowed':
      return {
        status: 'entry-unallowed',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        id: 3
      }
    case 'exited':
      return {
        status: 'exited',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        id: 3
      }
    case 'allowed-exit':
      return {
        status: 'allowed-exit',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        instruction: 'Please check bag and pockets',
        id: 3
      }
    case 'exit-unallowed':
      return {
        status: 'exit-unallowed',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        id: 3
      }
    case 'notified':
      return {
        status: 'notified',
        created_at: 'Jan 1, 2020 12:01 AM',
        first_name: 'THREE',
        last_name: 'CRUZ',
        id: 3
      }
    default:
      return null
  }

}


export const checkCode = (code) => dispatch => {
  dispatch({type: SUBMITTING_SCAN})

  const res = fetchCode(code) // temporary for testing
  dispatch({type: SET_LATEST, payload: res})

  // switch (res.status) {
  //   case 'entered':
  //     dispatch({type: SET_LATEST, payload: res})
  //     return
  //   case 'allowed-entry':
  //     dispatch({type: SET_LATEST, payload: res})
  //     return
  //   case 'entry-unallowed':
  //     // showEntryUnallowed()
  //     return
  //   case 'exited':
  //     // showExited()
  //     return
  //   case 'exited-allowed':
  //     // showExitInstruction()
  //     return
  //   case 'exit-unallowed':
  //     // showExitUnallowed()
  //     return
  //   default:
  //     return
  // }

}
