import {
  SET_MODE,
  SHOW_SWITCHER
} from '../const/mode_switcher_const'

export const changeMode = (mode) => dispatch => {
  dispatch({
    type: SET_MODE,
    payload: mode
  })
}

export const showSwitcherMode = () => dispatch => {
  dispatch({
    type: SHOW_SWITCHER,
    payload: {}
  })
}
