import _ from 'lodash'
import {
  LOAD_CLAIMS_SUMMARY,
  SHOW_CLAIMS_INSIDE,
  SHOW_CLAIMS_OUTSIDE,
  SHOW_CLAIMS,
  SHOW_CLAIMING_MODE,
  SET_CLAIMABLE_SUMMARY
} from '../const/claims_const'

const initialState = {
  inside_list: [],
  outside_list: [],
  display_mode: 'inside',
  claimable_id: null,
  claimed: [],
  unclaimed: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_CLAIMS:
      return {
        ...state,
        claims: action.payload,
        display_mode: 'list'
      }
    case SHOW_CLAIMING_MODE:
      return {
        ...state,
        display_mode: 'claiming_mode',
        claimable_id: action.payload.id
      }
    case SET_CLAIMABLE_SUMMARY:
      return {
        ...state,
        claimed:    action.payload.claimed,
        unclaimed:  action.payload.unclaimed,
      }
    case LOAD_CLAIMS_SUMMARY:
    _.filter(action.payload, ['in_premises', 'inside'])

      return {
        ...state,
        inside_list:  _.filter(action.payload, ['in_premises', 'inside']),
        outside_list: _.filter(action.payload, ['in_premises', 'outside']),
      }
    case SHOW_CLAIMS_INSIDE:
      return {
        ...state,
        display_mode: 'inside'
      }
    case SHOW_CLAIMS_OUTSIDE:
      return {
        ...state,
        display_mode: 'outside'
      }
    default:
      return state
  }
}