import React from "react"
import { useSelector } from 'react-redux'
import AttendanceList from "../attendance/attendance_summary"
import ClaimsList from "../claims/claims_summary"

export default () => {
  const { mode } = useSelector((state) => state.switcher)

  if(mode === 'claim') {
    return <ClaimsList />
  } else {
    return <AttendanceList />
  }

}
