import _ from 'lodash'
import {
  SHOW_SWITCHER,
  HIDE_SWITCHER,
  SET_MODE
} from '../const/mode_switcher_const'

const initialState = {
  show_switcher: false,
  mode: 'entry'
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SHOW_SWITCHER:
      return {
        ...state,
        show_switcher: true
      }
    case HIDE_SWITCHER:
      return {
        ...state,
        show_switcher: false
      }
    case SET_MODE:
      return {
        ...state,
        mode: action.payload,
        show_switcher: false
      }
    default:
      return state
  }
}