import _ from 'lodash'
import {
  SET_LATEST,
  SUBMITTING_SCAN,
} from '../const/scans_const'

const initialState = {
  latest_scan: {},
  loading_scan: false,
}

export default function(state = initialState, action) {
  switch (action.type) {
    case SET_LATEST:
      return {
        ...state,
        latest_scan: action.payload,
        loading_scan: false
      }
    case SUBMITTING_SCAN:
      return {
        ...state,
        latest_scan: {},
        loading_scan: true
      }
    default:
      return state
  }
}