import _ from 'lodash'
import {
  LOAD_ATTENDANCE_SUMMARY,
  SHOW_ATTENDANCE_INSIDE,
  SHOW_ATTENDANCE_OUTSIDE  
} from '../const/attendance_const'

const sample_data = [
  {id: '1', last_name: "CRUZ1", first_name: "JUAN"},
  {id: '2', last_name: "CRUZ", first_name: "TWO"},
  {id: '3', last_name: "CRUZ", first_name: "THREE"},
  {id: '4', last_name: "CRUZ", first_name: "FOUR"},
  {id: '5', last_name: "CRUZ", first_name: "FIVE"},
  {id: '6', last_name: "CRUZ", first_name: "SIX"},
  {id: '7', last_name: "CRUZ", first_name: "SEVEN"},
  {id: '8', last_name: "CRUZ", first_name: "EIGHT"},
  {id: '9', last_name: "CRUZ", first_name: "NINE"},
];

const initialState = {
  inside_list: [],
  outside_list: [],
  display_mode: 'inside'
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_ATTENDANCE_SUMMARY:
    _.filter(action.payload, ['in_premises', 'inside'])

      return {
        ...state,
        inside_list:  _.filter(action.payload, ['in_premises', 'inside']),
        outside_list: _.filter(action.payload, ['in_premises', 'outside']),
      }
    case SHOW_ATTENDANCE_INSIDE:
      return {
        ...state,
        display_mode: 'inside'
      }
    case SHOW_ATTENDANCE_OUTSIDE:
      return {
        ...state,
        display_mode: 'outside'
      }
    default:
      return state
  }
}