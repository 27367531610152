import {
  LOAD_ATTENDANCE_SUMMARY,
  SHOW_ATTENDANCE_INSIDE,
  SHOW_ATTENDANCE_OUTSIDE
} from '../const/attendance_const'

import {
  doAuthenticatedPost
} from '../../actions/_methods'

export const loadSummary = () => dispatch => {
  doAuthenticatedPost("/gate/attendances/list", {}, {
    success:  (res) => {
      console.log(res.data)
      dispatch({type: LOAD_ATTENDANCE_SUMMARY, payload: res.data})
    },
    error:    (res) => alert('Please reload'),
    catch:    (res) => alert('Please reload')
  })
}

export const showInside = () => dispatch => {
  dispatch({
    type: SHOW_ATTENDANCE_INSIDE,
    payload: {}
  })
}

export const showOutside = () => dispatch => {
  dispatch({
    type: SHOW_ATTENDANCE_OUTSIDE,
    payload: {}
  })
}

export const changeAttendanceView = (mode) => dispatch => {
  if(mode === 'in') {
    dispatch(showInside())
  } else if(mode === 'out') {
    dispatch(showOutside())
  }
}
