import React from "react"

import LayoutGate from "./common/layout"
import SEO from "../../components/seo"
import Scanner from "./scanner/scanner"
// import WaitList from "./waiting/wait_list"
// import AttendanceList from "./attendance/attendance_summary"
import Display from "../components/common/display"

const Dashboard = () => (
  <LayoutGate>
    <SEO title="Dashboard" />
    <div className="m-3">
      <div className="d-flex justify-content-evenly">
        <div className="" style={{width: "600px", padding: '10px'}}>
          <Scanner />
        </div>
        <div className="w-100">
          {/* <WaitList /> */}
          <Display />
        </div>
      </div>
    </div>
  </LayoutGate>
)

export default Dashboard