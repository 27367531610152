import React, { useState } from "react"
import { useDispatch } from 'react-redux'
import { doCommand } from '../../actions/general_action'

const CommandScanner = () => {
  const dispatch = useDispatch()
  const [command, setCommand] = useState('')

  const onChange = (e) => {
    setCommand(e.target.value)
  }

  const onKeyPress = (e) => {
    if((e.which || e.keyCode) === 13) {
      dispatch(doCommand(command))
      setCommand('')
    }
  }

  return(
    <>
      <div>
        <input
          id="main-command-scanner"
          type="text"
          className="form-control form-control-lg"
          value={command}
          onChange={onChange}
          onKeyPress={onKeyPress}
        />
      </div>
    </>
  )
}

export default CommandScanner
