import React from "react"
import { useSelector, useDispatch } from 'react-redux'
import _ from 'lodash'
import Barcode from "react-barcode"
import { doCommand } from '../../actions/general_action'
// import Claimable from "./claimable"

export default () => {
  const dispatch = useDispatch()
  const { claimable_id, claims, claimed, unclaimed } = useSelector((state) => state.claims)
  const target_claim = _.find(claims, { id: parseInt(claimable_id) })

  const unclaimAction = (id) => {
    dispatch(doCommand(`undo_cl-${id}`))
  }

  const claimedView = (claimable) => {
    return(
      <>
        <div
          className="gate-waiting border border-3 rounded p-3 m-3 bg-light"
          style={{width: '275px', cursor: 'pointer'}}
          onClick={()=>unclaimAction(claimable.id)}
        >
          <div className="mb-3">
            <p>{claimable.first_name} {claimable.last_name}</p>
            <h5>{claimable.description} &nbsp;</h5>

            {barCode(`undo_cl-${claimable.id}`)}
          </div>
        </div>
      </>
    )
  }

  const barCode = (bar_code) => <Barcode value={bar_code} width={1.5} height={50} displayValue={true} />

  return(
    <div className="p-0">
      <h3>Claiming <span className="text-primary">{target_claim.name}</span></h3>
      <div className="d-flex flex-wrap justify-content-between mt-3">
        <h5 className="text-success">Claimed: {claimed.length}</h5>
        <h5 className="text-danger">Unclaimed: {unclaimed.length}</h5>
      </div>
      <div className="d-flex flex-wrap mt-3">
        {claimed.map(c => claimedView(c))}
      </div>
    </div>
  )
}
