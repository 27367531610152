import React, { useEffect } from "react"
import { Router } from "@reach/router"
import { hasAuthToken } from '../actions/_auth'

import { Provider } from 'react-redux'
import store from '../_gate/store'

// import Dashboard    from "./gate/dashboard"
import Dashboard    from "../_gate/components/dashboard"

export default () => {
  useEffect(() => {
    if(hasAuthToken() === false) {
      const windowGlobal = typeof window !== 'undefined' && window
      windowGlobal.location = '/'
    } else {
      // get organization data
    }
  }, [])

  return(
    <Provider store={store}>
      <Router basepath="gate">
        <Dashboard path="/" />
      </Router>
    </Provider>
  )
}
