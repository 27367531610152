import {
  SET_LATEST,
  SUBMITTING_SCAN
} from '../const/scans_const'

import {
  doAuthenticatedPost
} from '../../actions/_methods'

import {
  loadSummary
} from './attendance_action'

import {
  showClaimingMode
} from './claims_action'

export const activityLog = (code, mode, other_id) => dispatch => {
  dispatch({type: SUBMITTING_SCAN})
  const error_res = {status: `invalid`}

  if(mode === 'claim') {
    doAuthenticatedPost("/gate/attendances/scan", {scan_mode: mode, code: code, claimable_id: other_id}, {
      success:  (res) => {
        dispatch({type: SET_LATEST, payload: res.data})
        dispatch(showClaimingMode(other_id))
      },
      error:    (res) => dispatch({type: SET_LATEST, payload: error_res}),
      catch:    (res) => dispatch({type: SET_LATEST, payload: error_res})
    })
  } else {
    doAuthenticatedPost("/gate/attendances/scan", {scan_mode: mode, code: code}, {
      success:  (res) => {
        dispatch({type: SET_LATEST, payload: res.data})
        dispatch(loadSummary())
      },
      error:    (res) => dispatch({type: SET_LATEST, payload: error_res}),
      catch:    (res) => dispatch({type: SET_LATEST, payload: error_res})
    })
  }
}