import React from "react"
import { useSelector } from 'react-redux'

import Loading from '../../../components/common/loading'
import Entered from './feedback/entered'
import EntryInstruction from './feedback/entry_instruction'
import EntryUnallowed from './feedback/entry_unallowed'
import Exited from './feedback/exited'
import ExitedInstruction from './feedback/exit_instruction'
import ExitUnallowed from './feedback/exit_unallowed'
import ClaimUnallowed from './feedback/claim_unallowed'
import Notified from './feedback/notified'
import Invalid from './feedback/invalid'

const ScanFeedback = () => {
  const { latest_scan, loading_scan } = useSelector((state) => state.scans)

  const displayAction = () => {
    if(latest_scan && latest_scan.status) {
      switch (latest_scan.status) {
        case 'entered':
          return <Entered scan={latest_scan} />
        case 'allowed-entry':
          return <EntryInstruction scan={latest_scan} />
        case 'entry-unallowed':
          return <EntryUnallowed scan={latest_scan} />
        case 'exited':
          return <Exited scan={latest_scan} />
        case 'allowed-exit':
          return <ExitedInstruction scan={latest_scan} />
        case 'exit-unallowed':
          return <ExitUnallowed scan={latest_scan} />
        case 'notified':
          return <Notified scan={latest_scan} />
        case 'claim-unallowed':
          return <ClaimUnallowed scan={latest_scan} />
        case 'invalid':
          return <Invalid scan={latest_scan} />
        default:
          return null
      }
    } else {
      return null
    }
  }

  if(loading_scan) {
    return <Loading />
  } else {
    return displayAction()
  }

}

export default ScanFeedback
