import {
  LOAD_CLAIMS_SUMMARY,
  SHOW_CLAIMS_INSIDE,
  SHOW_CLAIMS_OUTSIDE,
  SHOW_CLAIMS,
  SHOW_CLAIMING_MODE,
  SET_CLAIMABLE_SUMMARY
} from '../const/claims_const'

import {
  doAuthenticatedPost
} from '../../actions/_methods'

export const loadSummary = () => dispatch => {
  doAuthenticatedPost("/gate/claimables/list", {}, {
    success:  (res) => {
      console.log(res)
      dispatch({type: SHOW_CLAIMS, payload: res.claimables})
    },
    error:    (res) => alert('Please reload'),
    catch:    (res) => alert('Please reload')
  })
}

export const showClaimingMode = (id) => dispatch => {
  dispatch({
    type: SHOW_CLAIMING_MODE,
    payload: {id: id}
  })

  doAuthenticatedPost("/gate/claimables/summary", {id: id}, {
    success:  (res) => {
      console.log(res)
      dispatch({type: SET_CLAIMABLE_SUMMARY, payload: res})
    },
    error:    (res) => alert('Please reload'),
    catch:    (res) => alert('Please reload')
  })
}

export const undoClaiming = (id) => dispatch => {
  doAuthenticatedPost("/gate/claimables/undo_claim", {id: id}, {
    success:  (res) => {
      console.log(res)
      dispatch({type: SET_CLAIMABLE_SUMMARY, payload: res})
    },
    error:    (res) => alert('Please reload'),
    catch:    (res) => alert('Please reload')
  })
}

export const showInside = () => dispatch => {
  dispatch({
    type: SHOW_CLAIMS_INSIDE,
    payload: {}
  })
}

export const showOutside = () => dispatch => {
  dispatch({
    type: SHOW_CLAIMS_OUTSIDE,
    payload: {}
  })
}

export const changeAttendanceView = (mode) => dispatch => {
  if(mode === 'in') {
    dispatch(showInside())
  } else if(mode === 'out') {
    dispatch(showOutside())
  }
}
