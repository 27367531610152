import React from "react"
import { useDispatch } from 'react-redux'
import Barcode from "react-barcode"

import { doCommand } from '../../actions/general_action'

export default ({claimable}) => {
  const dispatch = useDispatch()

  const {id, uuid, name, status} = claimable

  const viewDetail = () => {
    dispatch(doCommand(`claims-${id}`))
  }

  // const barCode = <Barcode value={`opt-${id}`} width={1.5} height={50} displayValue={true} />
  const barCode = <Barcode value={`claims-${id}`} width={1.5} height={50} displayValue={true} />

  return(
    <>
      <div
        className="gate-waiting border border-3 rounded p-3 m-3 bg-light"
        style={{width: '275px', cursor: 'pointer'}}
        onClick={viewDetail}
      >
        <div className="mb-3 text-left">
          <h3 className="mb-0">{name}</h3>
          <span>{status}</span>
        </div>
        <div className="text-center">{barCode}</div>
      </div>
    </>
  )
}
