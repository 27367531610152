import React from "react"

const EntryUnallowed = ({scan}) => {
  const {id, last_name, first_name} = scan

  return(<>
    <div className="border mt-3 p-3">
      <div className="d-flex">
        <img src={`https://randomuser.me/api/portraits/men/${id}.jpg`} className="img-thumbnail rounded mr-3" />
        <h4 className="ms-3">{last_name}<br/>{first_name}</h4>
      </div>
      <div className="alert my-3 text-center bg-danger">
        <h5 className="m-0 text-white">SORRY NOT ALLOWED TO ENTER</h5>
      </div>
    </div>
  </>)
}

export default EntryUnallowed