import React from "react"
import { useDispatch } from 'react-redux'
import Barcode from "react-barcode"
import {
  doCommand
} from '../../../actions/general_action'


const EntryInstruction = ({scan}) => {
  const dispatch = useDispatch()
  const {id, last_name, first_name, created_at, instruction} = scan

  return(<>
    <div className="border mt-3 p-3">
      <div className="d-flex">
        <img src={`https://randomuser.me/api/portraits/men/${id}.jpg`} className="img-thumbnail rounded mr-3" />
        <h4 className="ms-3">{last_name}<br/>{first_name}</h4>
      </div>
      <div className="alert my-3 text-center bg-warning">
        <h5 className="m-0">BEFORE ALLOW ENTRY</h5>
        <small className="text-center">{created_at}</small>
      </div>
      <div className="text-center">
        <Barcode value={`allow-entry-${id}`} width={1.5} height={50} displayValue={true} />
        <br/>
        <button className="btn btn-success btn-lg" onClick={() => dispatch(doCommand(`allow-entry-${id}`))}>ALLOW ENTRY</button>
      </div>
      <div className="border border-warning border-5 p-3 bg-light mt-3">
        <p>{instruction}</p>
      </div>
    </div>
  </>)
}

export default EntryInstruction