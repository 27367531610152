import _ from 'lodash'
import {
  LOAD,
  DETAIL,
} from '../const/waitings_const'

const initialState = {
  list: [],
  shown: true,
  person: {}
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        shown: true,
        person: {},
        list: [
          {id: '1', last_name: "CRUZ1", first_name: "JUAN"},
          {id: '2', last_name: "CRUZ", first_name: "TWO"},
          {id: '3', last_name: "CRUZ", first_name: "THREE"},
          {id: '4', last_name: "CRUZ", first_name: "FOUR"},
          {id: '5', last_name: "CRUZ", first_name: "FIVE"},
          {id: '6', last_name: "CRUZ", first_name: "SIX"},
          {id: '7', last_name: "CRUZ", first_name: "SEVEN"},
          {id: '8', last_name: "CRUZ", first_name: "EIGHT"},
          {id: '9', last_name: "CRUZ", first_name: "NINE"},
        ]
      }
    case DETAIL:
      return {
        ...state,
        shown: false,
        person: _.find(state.list, ['id', action.payload])
      }
    default:
      return state
  }
}