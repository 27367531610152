import React, { useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import Barcode from "react-barcode";
import { doCommand } from '../../actions/general_action';

export default () => {
  const dispatch = useDispatch();
  const show_switcher = useSelector(state => state.switcher.show_switcher);
  const mode = useSelector(state => state.switcher.mode);
  // const show_switcher = true;

  const onChange = (value) => {
    dispatch(doCommand(value));
  };

  const onShowSwitcher = () => {
    dispatch(doCommand('switcher'));
  }

  const bgClass = () => {
    if(mode === 'entry') {
      return 'alert-success'
    } else if(mode === 'exit') {
      return 'alert-danger'
    } else if(mode === 'claim') {
      return 'alert-warning'
    }
  }

  const modeDisplay = () => (
    <div className={`alert p-1 d-flex justify-content-between align-items-center ${bgClass()}`} onClick={onShowSwitcher}>
      <strong className="m-0 ps-3">MODE: {mode}</strong>
      <Barcode value={`switcher`} width={1.5} height={40} displayValue={false} />
    </div>
  );

  const entryMode = () => (
    <div className="alert alert-success text-center" onClick={()=>onChange('mode-entry')}>
      <Barcode value={`mode-entry`} width={1.5} height={50} displayValue={true} />
      {/* <h3 className="mb-0">ENTRY MODE</h3> */}
    </div>
  );

  const exitMode = () => (
    <div className="alert alert-danger text-center" onClick={()=>onChange('mode-exit')}>
      <Barcode value={`mode-exit`} width={1.5} height={50} displayValue={true} />
      {/* <h3 className="mb-0">EXIT MODE</h3> */}
    </div>
  );

  const claimMode = () => (
    <div className="alert alert-warning text-center" onClick={()=>onChange('mode-claim')}>
      <Barcode value={`mode-claim`} width={1.5} height={50} displayValue={true} />
      {/* <h3 className="mb-0">EXIT MODE</h3> */}
    </div>
  );

  if(show_switcher) {
    return(
      <>
        <div className="mt-4">
          {entryMode()}
          {exitMode()}
          {claimMode()}
        </div>
      </>
    )
  } else {
    return(
      <>
        <div className="mt-4">
          {modeDisplay()}
        </div>
      </>
    )
  }

  // return(show_switcher &&
  //   <>
  //     <div className="mt-4">
  //       {entryMode()}
  //       {exitMode()}
  //       {claimMode()}
  //     </div>
  //   </>
  // )
};
