import React from "react"
import Member from "./member"

export default ({list}) => {
  return(
    <div className="p-0">
      <div className="d-flex flex-wrap">
        {list.map(i => <Member person={i} />)}
      </div>
    </div>
  )
}
