import React from "react"

const Entered = ({scan}) => {
  const {id, last_name, first_name, created_at} = scan

  return(<>
    <div className="border mt-3 p-3">
      <div className="d-flex">
        <img src={`https://randomuser.me/api/portraits/men/${id}.jpg`} className="img-thumbnail rounded mr-3" />
        <h4 className="ms-3">{last_name}<br/>{first_name}</h4>
      </div>
      <div className="alert my-3 text-center bg-info">
        <h5 className="m-0">ENTERED - WELCOME</h5>
      </div>
      <h5 className="text-center">
        {created_at}
        {/* 10:30:01 AM - Jan 31 */}
      </h5>
    </div>
  </>)
}

export default Entered