import { combineReducers } from 'redux'

import WaitingsReducer      from './waitings_reducer'
import ScansReducer         from './scans_reducer'
import ModeSwitcherReducer  from './mode_switcher_reducer'
import AttendanceReducer    from './attendance_reducer'
import ClaimsReducer        from './claims_reducer'

export default combineReducers({
  waitings:   WaitingsReducer,
  scans:      ScansReducer,
  switcher:   ModeSwitcherReducer,
  attendance: AttendanceReducer,
  claims:     ClaimsReducer,
})